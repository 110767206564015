import { Formik } from "formik"
import { useState } from "react"
import * as Yup from "yup"
import { AuthContextType, useAuthContext } from "../../contexts/auth-context"
import { Box } from "@mui/system"
import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material"
import { ConfirmationForm } from "./ConfirmationForm"
import { MojeButton } from "../../components/ui/MojeButton"
import { MojeTextInput } from "../../components/ui/MojeTextInput"
import { useNavigate } from "react-router-dom"
import { useAuth } from "wakacje-web/src/auth/AuthContext"
import { LinkUtils } from "wakacje-web/src/shared/LinkUtils"
import { PasswordTooltip } from "./PasswordTooltip"

const STEP_REGISTER = "REGISTER"
const STEP_CONFIRM = "CONFIRM"

const formSchema = Yup.object({
  type: Yup.string().required("Wprowadź rodzaj konta"),
  name: Yup.string().required("Wprowadź imię"),
  last_name: Yup.string().required("Wprowadź nazwisko"),
  email: Yup.string()
    .email("E-mail nie jest poprawny")
    .required("Wprowadź poprawny e-mail"),
  password: Yup.string()
    .required("Wprowadź nowe hasło")
    .min(
      8,
      "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę, a także może zawierać opcjonalne znaki @$!%*#?&"
    )
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę, a także może zawierać opcjonalne znaki @$!%*#?&"
    ),
  phone: Yup.string()
    .required("Wprowadź numer telefonu")
    .min(8, "Numer telefonu jest niepoprawny"),
  agree1: Yup.boolean().isTrue(),
  agree2: Yup.boolean().isTrue(),
  passwordCorrect: Yup.boolean().required().isTrue()
})

export const CompanyRegistrationForm = (props: any) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentStep, setCurrentStep] = useState(STEP_REGISTER)
  const [signupParams, setSignUpParams] = useState<any>(undefined)
  const [errorCode, setErrorCode] = useState()

  const authContext: AuthContextType = useAuthContext()
  const navigate = useNavigate()
  const { user, signup, logout } = useAuth()

  const handleConfirm = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    // console.log("handleConfirm", values)
    // try {
    //   const res = await Auth.confirmSignUp(values.username, values.confirmation)
    //   console.log("Confirmation result", res)
    //   if (res === "SUCCESS") {
    //     // showToastr("Account confirmed!")
    //     //   onConfirmed()
    //   }
    // } catch (error) {
    //   console.log("Confirmation error" + error)
    //   // setConfirmationResult(error)
    // }
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    console.log("handleSubmit", currentStep)
    if (currentStep === STEP_CONFIRM) {
      return handleConfirm(values, { setSubmitting })
    }

    try {
      setSubmitting(true)
      setErrorMessage(undefined)
      console.log("call signup")
      var res = await signup(
        values.email.trim(),
        values.password.trim(),
        values.name,
        values.last_name,
        values.phone,
        LinkUtils.getAdminHref()
      )
      console.log("Sign upr esult ", res)
      // Save phone to our db
      props.onRegistered(res.user)
      if (res.user && res.user.emailVerified === false) {
        // we got user - redirect to confirmation page
        // setSignUpParams(_signUpParams)
        setCurrentStep(STEP_CONFIRM)
        console.log("Login result")
        // setConfirmationCodeMedium(res.codeDeliveryDetails.DeliveryMedium)
      } else {
        authContext.refetchCurrentUser()
      }
    } catch (error: any) {
      const _errorCode = error.code
      const _errorMessage = error.message
      // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
      console.log("errorCode", _errorCode)
      console.log("Error register", error)
      console.log("Message")
      console.log(error.message)
      setErrorCode(_errorCode)
      console.dir()
      setSubmitting(false)
      if (error.code === "InvalidParameterException") {
        setErrorMessage(
          "Błędne hasło - nowe hasło musi mieć minimum 8 znaków w tym "
        )
      } else if (
        error.code === "UsernameExistsException" ||
        error.code === "auth/email-already-in-use"
      ) {
        setErrorMessage("To konto już istnieje")
      } else {
        setErrorMessage(error.message)
      }
    }
  }

  const initialValues: any = {
    name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    agree1: false,
    agree2: false,
    type: "organizator"
  }

  return (
    <Box>
      <Box>
        {currentStep === STEP_CONFIRM && (
          <ConfirmationForm
            signupParams={signupParams}
            onConfirmed={props.onConfirmed}
          />
        )}
        {currentStep === STEP_REGISTER && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={formSchema}
            validateOnMount
            validateOnChange
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              submitForm,
              isValid,
              submitCount
            }) => (
              <Stack
                component="form"
                onSubmit={handleSubmit}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    submitForm()
                  }
                }}
              >
                <MojeTextInput
                  valid={values.name && !errors.name}
                  label="Imię"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                  required
                  errors={errors}
                  touched={touched}
                />

                <MojeTextInput
                  valid={values.last_name && !errors.last_name}
                  label="Nazwisko"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  name="last_name"
                  required
                  errors={errors}
                  touched={touched}
                />
                <MojeTextInput
                  valid={values.email && !errors.email}
                  label="E-mail"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  required
                  errors={errors}
                  touched={touched}
                />

                <MojeTextInput
                  valid={values.email && !errors.email}
                  label="Telefon"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  name="phone"
                  required
                  errors={errors}
                  touched={touched}
                />

                {currentStep === STEP_REGISTER && (
                  <PasswordTooltip setFieldValue={setFieldValue}>
                    <MojeTextInput
                      label="Hasło"
                      variant="outlined"
                      size="small"
                      margin="normal"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      name="password"
                      required
                      errors={{ ...errors, passwordCorrect: {} }}
                      touched={touched}
                      valid={
                        values.password &&
                        !errors.password &&
                        !errors.passwordCorrect
                      }
                      passwordField
                    />
                  </PasswordTooltip>
                )}
                <div className="mt-5"></div>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <div className="custom-checkbox">
                        <Checkbox
                          sx={{
                            alignSelf: "start",
                            fontSize: 0.75
                          }}
                          name="agree1"
                          checked={values.agree1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </div>
                    }
                    className="text-white"
                    label={
                      <div className="text-sm">
                        Wyrażam zgodę na przetwarzanie danych osobowych
                        zawartych w karcie kwalifikacyjnej na potrzeby niezbędne
                        do zapewnienia bezpieczeństwa i ochrony zdrowia
                        uczestnika wypoczynku (zgodnie z ustawą z dnia 29
                        sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z
                        2015 r. poz. 2135, z późn. zm.)).
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <div className="custom-checkbox">
                        <Checkbox
                          sx={{ fontSize: 0.75 }}
                          name="agree2"
                          checked={values.agree2}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    }
                    className="text-white"
                    label={
                      <div className="text-sm">
                        {"Klikając przycisk poniżej akceptujesz "}
                        <a
                          href={`https://admin.${process.env.NEXT_PUBLIC_DOMAIN}/regulamin`}
                          target="_blank"
                          className="underline cursor-pointer text-[color:var(--fc-color-primary-blue)] "
                          rel="noreferrer"
                        >
                          <span className="">Regulamin</span>
                        </a>
                      </div>
                    }
                  />
                </FormGroup>

                <div className="w-full mt-5">
                  <MojeButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    label="Załóż konto"
                    isSaving={isSubmitting}
                    disabled={!isValid}
                  />
                </div>

                {/* {this.renderLoginWithFacebook()} */}
                {/* <div className="renderLoginWithFacebook" */}
                {/* <div className="col-md-6">
                        <MpsButton
                          type="button"
                          onClick={async () => {
                            await setFieldValue('mode', 'signup', false)
                            submitForm()
                          }}
                          className="btn btn-tool btn-primary"
                          tabIndex="50"
                          style={{
                            width: '100%',
                            borderRadius: 4,
                            backgroundColor: '#ff8204',
                            color: 'white'
                          }}
                          label="Sign Up"
                        />
                      </div> */}
                {/* {JSON.stringify(values)} */}

                {errorMessage && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: 10,
                      height: 30,
                      color: "red"
                    }}
                  >
                    {errorMessage}
                  </div>
                )}
                <div className="py-5">
                  <p
                    className="text-white text-center cursor-pointer"
                    onClick={() => navigate("/login")}
                  >
                    Mam już konto
                  </p>
                </div>
              </Stack>
            )}
          </Formik>
        )}
      </Box>
    </Box>
  )
}
